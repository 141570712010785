:root {
  /**********/
  /* COLORS */
  /**********/
  --fg: 200,200,200;
  --fg-dim0: 145,145,145;
  --fg-dim1: 120,120,120;
  --fg-dim2: 105,105,105;
  --fg-dim3: 90,90,90;
  --bg: 51,51,51;
  --bg2: 33,33,33;

  --accent1: 254,172,31;
  --accent2: 209,142,28;

  /***********/
  /* SPACING */
  /***********/
  --s: calc(1rem / 4);

  /**************/
  /* TYPOGRAPHY */
  /**************/
  --font-mono: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo,
    Consolas, 'DejaVu Sans Mono', monospace;
  --font-sans: var(--font-inter), Roboto, 'Helvetica Neue', 'Arial Nova',
    'Nimbus Sans', Helvetica, Arial, sans-serif; 

  /* font-sizes */
  --fz--6:0.4375rem;
  --fz--5:0.5rem;
  --fz--4:0.5625rem;
  --fz--3:0.6875rem;
  --fz--2:0.75rem;
  --fz--1:0.875rem;
  --fz-0:1rem;
  --fz-1:1.125rem;
  --fz-2:1.3125rem;
  --fz-3:1.5rem;
  --fz-4:1.75rem;
  --fz-5:2rem;
  --fz-6:2.3125rem;
  --fz-7:2.625rem;
  --fz-8:3.0625rem;
  --fz-9:3.5rem;
  --fz-10:4rem;
  --fz-11:4.625rem;
  --fz-12:5.25rem;
  --fz-13:6.0625rem;
  --fz-14:6.9375rem;
  --fz-15:8rem;
  --fz-16:9.1875rem;
  --fz-17:10.5625rem;
  --fz-18:12.125rem;
  --fz-19:13.9375rem;
  --fz-20:16rem;
  --fz-21:18.375rem;
  --fz-22:21.125rem;
  --fz-23:24.25rem;
  --fz-24:27.875rem;
  --fz-25:32rem;
  --fz-26:36.75rem;
  --fz-27:42.25rem;
  --fz-28:48.5rem;
  --fz-29:55.6875rem;
  --fz-30:64rem;

  /* line-hights */
  --lh--6:0.5rem;
  --lh--5:0.75rem;
  --lh--4:0.75rem;
  --lh--3:1rem;
  --lh--2:1.25rem;
  --lh--1:1.25rem;
  --lh-0:1.5rem;
  --lh-1:1.75rem;
  --lh-2:2rem;
  --lh-3:2rem;
  --lh-4:2.25rem;
  --lh-5:2.5rem;
  --lh-6:3rem;
  --lh-7:3.25rem;
  --lh-8:3.75rem;
  --lh-9:4rem;
  --lh-10:4.5rem;
  --lh-11:5.25rem;
  --lh-12:5.75rem;
  --lh-13:6.75rem;
  --lh-14:7.5rem;
  --lh-15:8.5rem;
  --lh-16:9.75rem;
  --lh-17:11.25rem;
  --lh-18:12.75rem;
  --lh-19:14.5rem;
  --lh-20:16.5rem;
  --lh-21:19rem;
  --lh-22:21.75rem;
  --lh-23:24.75rem;
  --lh-24:28.5rem;
  --lh-25:32.5rem;
  --lh-26:37.25rem;
  --lh-27:42.75rem;
  --lh-28:49rem;
  --lh-29:56.25rem;
  --lh-30:64.5rem;
}

@media all and (min-width:80em) {
  :root {
    --fz--6:0.5rem;
    --fz--5:0.5625rem;
    --fz--4:0.625rem;
    --fz--3:0.75rem;
    --fz--2:0.875rem;
    --fz--1:1rem;
    --fz-0:1.125rem;
    --fz-1:1.3125rem;
    --fz-2:1.5rem;
    --fz-3:1.6875rem;
    --fz-4:1.9375rem;
    --fz-5:2.25rem;
    --fz-6:2.5625rem;
    --fz-7:3rem;
    --fz-8:3.4375rem;
    --fz-9:3.9375rem;
    --fz-10:4.5rem;
    --fz-11:5.1875rem;
    --fz-12:5.9375rem;
    --fz-13:6.8125rem;
    --fz-14:7.8125rem;
    --fz-15:9rem;
    --fz-16:10.3125rem;
    --fz-17:11.875rem;
    --fz-18:13.625rem;
    --fz-19:15.6875rem;
    --fz-20:18rem;
    --fz-21:20.6875rem;
    --fz-22:23.75rem;
    --fz-23:27.3125rem;
    --fz-24:31.3125rem;
    --fz-25:36rem;
    --fz-26:41.375rem;
    --fz-27:47.5rem;
    --fz-28:54.5625rem;
    --fz-29:62.6875rem;
    --fz-30:72rem;
    --lh--6:0.75rem;
    --lh--5:0.75rem;
    --lh--4:1rem;
    --lh--3:1.25rem;
    --lh--2:1.25rem;
    --lh--1:1.5rem;
    --lh-0:1.75rem;
    --lh-1:2rem;
    --lh-2:2rem;
    --lh-3:2.25rem;
    --lh-4:2.5rem;
    --lh-5:2.75rem;
    --lh-6:3.25rem;
    --lh-7:3.5rem;
    --lh-8:4rem;
    --lh-9:4.5rem;
    --lh-10:5rem;
    --lh-11:5.75rem;
    --lh-12:6.5rem;
    --lh-13:7.5rem;
    --lh-14:8.5rem;
    --lh-15:9.5rem;
    --lh-16:11rem;
    --lh-17:12.5rem;
    --lh-18:14.25rem;
    --lh-19:16.25rem;
    --lh-20:18.5rem;
    --lh-21:21.25rem;
    --lh-22:24.25rem;
    --lh-23:28rem;
    --lh-24:32rem;
    --lh-25:36.5rem;
    --lh-26:42rem;
    --lh-27:48rem;
    --lh-28:55.25rem;
    --lh-29:63.25rem;
    --lh-30:72.5rem;
  }
}
@media all and (min-width:102em) {
  :root {
    --fz--6:0.5625rem;
    --fz--5:0.6875rem;
    --fz--4:0.75rem;
    --fz--3:0.875rem;
    --fz--2:1rem;
    --fz--1:1.125rem;
    --fz-0:1.3125rem;
    --fz-1:1.5rem;
    --fz-2:1.75rem;
    --fz-3:2rem;
    --fz-4:2.3125rem;
    --fz-5:2.625rem;
    --fz-6:3rem;
    --fz-7:3.4375rem;
    --fz-8:4rem;
    --fz-9:4.5625rem;
    --fz-10:5.25rem;
    --fz-11:6rem;
    --fz-12:6.9375rem;
    --fz-13:7.9375rem;
    --fz-14:9.125rem;
    --fz-15:10.5rem;
    --fz-16:12.0625rem;
    --fz-17:13.875rem;
    --fz-18:15.9375rem;
    --fz-19:18.3125rem;
    --fz-20:21rem;
    --fz-21:24.125rem;
    --fz-22:27.6875rem;
    --fz-23:31.8125rem;
    --fz-24:36.5625rem;
    --fz-25:42rem;
    --fz-26:48.25rem;
    --fz-27:55.4375rem;
    --fz-28:63.6875rem;
    --fz-29:73.125rem;
    --fz-30:84rem;
    --lh--6:0.75rem;
    --lh--5:1rem;
    --lh--4:1.25rem;
    --lh--3:1.25rem;
    --lh--2:1.5rem;
    --lh--1:1.75rem;
    --lh-0:2rem;
    --lh-1:2rem;
    --lh-2:2.25rem;
    --lh-3:2.5rem;
    --lh-4:3rem;
    --lh-5:3.25rem;
    --lh-6:3.5rem;
    --lh-7:4rem;
    --lh-8:4.5rem;
    --lh-9:5.25rem;
    --lh-10:5.75rem;
    --lh-11:6.5rem;
    --lh-12:7.5rem;
    --lh-13:8.5rem;
    --lh-14:9.75rem;
    --lh-15:11rem;
    --lh-16:12.75rem;
    --lh-17:14.5rem;
    --lh-18:16.5rem;
    --lh-19:19rem;
    --lh-20:21.5rem;
    --lh-21:24.75rem;
    --lh-22:28.25rem;
    --lh-23:32.5rem;
    --lh-24:37.25rem;
    --lh-25:42.5rem;
    --lh-26:48.75rem;
    --lh-27:56rem;
    --lh-28:64.25rem;
    --lh-29:73.75rem;
    --lh-30:84.5rem;
  }
}

*,
*::before,
*::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

*::before,
*::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  background-color: rgb(var(--bg));
  box-sizing: border-box;
  color: rgb(var(--fg));
  font-family: var(--font-sans);
  color-scheme: dark;
  overflow-x: hidden;
}

html,
body {
  max-width: 100vw;
}

body {
  font-size: var(--fz-0);
  line-height: var(--lh-0);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size:1em;
}

ol,
ul {
  list-style:none;
}
:where(
  :-webkit-any-link,
  :hover,
  :active,
  :focus
  :visited,
) {
  color: inherit;
  text-decoration:none;
  -webkit-text-decoration-skip:ink;
          text-decoration-skip-ink:auto;
}
:where(
  :any-link,
  :hover,
  :active,
  :focus
  :visited,
) {
  color: inherit;
  text-decoration:none;
  -webkit-text-decoration-skip:ink;
          text-decoration-skip-ink:auto;
}
b,
strong {
  font-weight:700;
}
::selection {
  background-color:rgb(var(--accent2));
  color:rgb(255,255,255);
  text-shadow:none;
}
img{
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  width: 100%;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align:middle;
}
svg {
  fill:currentColor;
}
button {
  all: unset;
}
button,
input,
optgroupselect,
textarea {
  color:inherit;
  font-family:inherit;
  font-size:inherit;
  line-height:inherit;
  margin:0;
}
button,
select {
  text-transform:none;
}
input,
button,
select,
textarea {
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  border:0;
}
:focus:focus:not(:focus-visible){
  outline:none;
}
::-moz-focus-inner {
  border-style:none;
  padding:0;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:focus:focus:not(:focus-visible) {
  outline:none;
}
:-moz-ui-invalid {
  box-shadow:none;
}
fieldset {
  border:0;
}
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  touch-action:manipulation;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_e66fe9';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_e66fe9 {font-family: '__Inter_e66fe9', '__Inter_Fallback_e66fe9';font-style: normal
}.__variable_e66fe9 {--font-inter: '__Inter_e66fe9', '__Inter_Fallback_e66fe9'
}

